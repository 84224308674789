<template>
  <div class="page">
    <Navbar title="推广订单" type="SMP" />
    <van-row class="count" :gutter="5">
      <van-col :span="8">
        <div class="item fc-cyan">
          <div class="title">订单总数</div>
          <div class="amount">{{ count.site.total + count.share.total }}个</div>
          <div class="increase">
            近七日新增{{ count.site.new + count.share.new }}个
          </div>
        </div>
      </van-col>
      <van-col :span="8">
        <div class="item fc-orange">
          <div class="title">分享订单</div>
          <div class="amount">{{ count.share.total }}个</div>
          <div class="increase">近七日新增{{ count.share.new }}个</div>
        </div>
      </van-col>
      <van-col :span="8">
        <div class="item fc-blue">
          <div class="title">摊位订单</div>
          <div class="amount">{{ count.site.total }}个</div>
          <div class="increase">近七日新增{{ count.site.new }}个</div>
        </div>
      </van-col>
    </van-row>
    <van-tabs
      :active="activeTab"
      border="true"
      :color="COLOR"
      @change="tabChange"
    >
      <van-tab title="工作招聘" name="RCT"> </van-tab>
      <van-tab title="证书培训" name="TRA"> </van-tab>
      <van-tab title="上学咨询" name="ERO"> </van-tab>
    </van-tabs>
    <van-row
      class="order bdc-b1s-gray"
      v-for="order in orderList"
      :key="order.code"
      @click="orderProcess(order.code, order.taskCode)"
    >
      <van-col span="24" class="content">
        <van-row>
          <van-col span="16" class="name text-short"
            >{{ order.taskName }}
            <van-tag
              mark
              type="primary"
              v-if="order.node === 'FVF'"
              :color="COLOR"
              >意向</van-tag
            >
            <van-tag mark type="warning" v-if="order.node === 'RVF'"
              >办理</van-tag
            ></van-col
          >
          <van-col span="8" class="amount color-s1"
            >收益:{{ order.amount }}元</van-col
          >
        </van-row>
        <van-row>
          <van-col span="16" class="code">订单号：{{ order.code }}</van-col>
          <van-col span="8">
            <van-tag
              type="primary"
              v-if="order.processState === 'CPT'"
              :color="COLOR"
              >已完成</van-tag
            >
            <van-tag type="warning" v-if="order.processState === 'RUN'"
              >办理中</van-tag
            >
            <van-tag type="danger" v-if="order.processState === 'STP'"
              >已终止</van-tag
            >
          </van-col>
        </van-row>
        <van-row>
          <van-col span="16" class="time">日期：{{ order.time }}</van-col>
          <van-col span="8">
            <van-tag
              plain
              type="primary"
              v-if="order.source === 'SHR'"
              :color="COLOR"
              >来源：分享</van-tag
            >
            <van-tag
              plain
              type="warning"
              v-if="order.source === 'STE'"
              :color="COLOR"
              >来源：摊位</van-tag
            >
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-row class="no-record" v-if="orderList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <Tabbar active="own" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../module/common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Share from '../../module/common/Share.vue'
import { Tabs, Tab, Image, Icon, Tag } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Tabbar: Tabbar,
    Share: Share,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Tag.name]: Tag
  },
  data () {
    return {
      activeTab: 'TRA',
      sellerCode: '',
      orderList: [],
      count: { site: { total: 0, new: 0 }, share: { total: 0, new: 0 } }
    }
  },
  mounted () {
    this.sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
    this.retrieveOrderCount()
    this.retrieveOrderList()
    this.$refs.share.default('seller')
  },
  methods: {
    async retrieveOrderCount () {
      var pd = { sellerCode: this.sellerCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/order/retrieveOrderSellerCount', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.count.site.total = res.data.siteTotal
        this.count.site.new = res.data.siteNew
        this.count.share.total = res.data.shareTotal
        this.count.share.new = res.data.shareNew
      }
    },
    tabChange (val) {
      this.activeTab = val
      this.retrieveOrderList()
    },
    async retrieveOrderList () {
      var pd = { sellerCode: this.sellerCode, businessType: this.activeTab, current: 1, size: 100 }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/order/retrieveOrderSellerList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.orderList = res.data
      }
    },
    orderProcess (orderCode) {
      if (this.activeTab === 'RCT') {
        this.$router.push({ path: '/recruit/orderProcess', query: { orderCode: orderCode } })
      } else if (this.activeTab === 'TRA') {
        this.$router.push({ path: '/train/orderProcess', query: { orderCode: orderCode } })
      } else if (this.activeTab === 'ERO') {
        this.$router.push({ path: '/enroll/orderProcess', query: { orderCode: orderCode } })
      }
    },
    taskProcess (taskCode) {

    }
  }
}
</script>
<style lang="less" scoped>
.count {
  height: 85px;
  width: 98%;
  margin: 5px auto 0px auto;
  .item {
    background-color: #f7f8fa;
    padding: 10px 0px;
    width: 100%;
    margin: 0px auto;
    border-radius: 5px;
    .title {
      font-size: 13x;
      height: 20px;
      line-height: 20px;
    }
    .amount {
      font-size: 15px;
      font-weight: 600;
      height: 20px;
      line-height: 20px;
    }
    .increase {
      font-size: 13x;
      height: 20px;
      line-height: 20px;
    }
  }
}
.order {
  padding: 5px 10px;
  .name {
    font-size: 14px;
    font-weight: 600;
    height: 25px;
    line-height: 25px;
    text-align: left;
  }
  .code {
    text-align: left;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .amount {
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
  }
  .time {
    text-align: left;
    height: 20px;
    line-height: 20px;
  }
  .btn {
    border-top: 1px solid #eee;
    padding-top: 5px;
  }
}
</style>
